import { useEffect, useState } from 'react';
import { Modal, Typography, Row, Col, Collapse, Divider, Form, message } from 'antd';
import { LockOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'afrodita';
import { isMobileOnly } from 'react-device-detect';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const { Text } = Typography;
const { Panel } = Collapse;

export type StoreAuth = {
	token: string;
	roles: string;
	operations: any;
};

export type ReduxStore = {
	auth: StoreAuth;
};
type Props = {
	userMortgageId: any;
	clickTab: any;
	env: any;
	mortgage: any;
	platform: string;
	traceSadabdell: any;
};

function AutomaticDocumentationStartupModal({
	userMortgageId,
	clickTab,
	mortgage,
	env,
	platform,
	traceSadabdell
}: Props) {
	const { t, i18n } = useTranslation();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [activeModals, setActiveModals] = useState(false);
	const [activeButton, setActiveButton] = useState(true);
	const [showSecurity, setShowSecurity] = useState(false);
	const showSavings = false; //mostrar o no el input de ahorros en el modal de documentación de sabadell
	const operationType = mortgage.subtype;
	const [closeDisabled, setCloseDisabled] = useState(
		operationType === 'mortgage' && platform === 'sabadell' && showSavings ? true : false
	);

	useEffect(() => {
		if (mortgage?.operationData[clickTab]?.viewModalsAutomatic) {
			setActiveModals(false);
		} else {
			setActiveModals(true);
			saveData();
			if (traceSadabdell) {
				traceSadabdell('inicio', i18n.language === 'cat' ? 'ca' : 'es');
			}
		}

		setTimeout(() => {
			setActiveButton(false);
		}, [3000]);
	}, []);

	const saveData = () => {
		const key = 'viewModalsAutomatic';
		const participant = clickTab;
		const value = true;

		const key2 = 'docOnboardingDate';
		const participant2 = 'process';
		const value2 = moment().format('YYYY-MM-DD');

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${userMortgageId}`,
				{
					values: [
						{
							key: participant,
							subkey: key,
							value: value
						},
						{
							key: key2,
							subkey: participant2,
							value: value2
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {});
	};

	const sendViability = () => {
		axios
			.post(
				`${env.api.url}/mortgages/conventional-viability-mortgage/get-viability/${userMortgageId}`,
				{},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {});
	};

	const clickNextModal2 = () => {
		if (platform === 'sabadell' && operationType === 'mortgage' && showSavings) {
			const savings = form.getFieldValue('savings');
			axios
				.post(
					`${env.api.url}/mortgages/calculate-ltv-dti/save-mortgage-amount`,
					{ savings, operationId: userMortgageId },
					{ headers: { Authorization: `Bearer ${userToken}` } }
				)
				.then(() => {
					axios
						.post(
							`${env.api.url}/v1/mortgages/data/multiple/update/${userMortgageId}`,
							{
								values: [
									{
										key: 'mortgage',
										subkey: 'savings',
										value: savings
									},
									{
										key: 'process',
										subkey: 'isSabadellSavingsEntered',
										value: true
									}
								]
							},
							{ headers: { Authorization: `Bearer ${userToken}` } }
						)
						.then((response) => {
							if (response.data.success) {
								sendViability();
								setActiveModals(false);
								navigate(`/${userMortgageId}/${clickTab}/automatic-documentation`);
							} else {
								message.error('Ha ocurrido un error');
							}
						});
				});
		} else {
			setActiveModals(false);
			navigate(`/${userMortgageId}/${clickTab}/automatic-documentation`);
		}
	};

	const renderSteps = (
		image: string,
		title: string,
		description: string,
		first: boolean,
		last: boolean
	) => {
		return (
			<Col span={isMobileOnly ? 24 : 8}>
				<div style={{ maxWidth: 230, textAlign: isMobileOnly ? 'left' : 'center' }}>
					{isMobileOnly ? (
						<Row gutter={4}>
							<Col span={4}>
								<div style={{ marginTop: 8 }}>
									<img
										src={`/images/documents/${image}`}
										alt="Documents icon"
										width={32}
										height={32}
									/>
									{!last && (
										<Divider
											type="vertical"
											style={{ borderColor: '#02C3CD', height: 40, marginLeft: 15 }}
										/>
									)}
								</div>
							</Col>
							<Col span={20}>
								<Text style={{ fontSize: 14, fontWeight: 600, color: '#748EA0' }}>
									{' '}
									{t(title)}{' '}
								</Text>{' '}
								<br />
								<Text
									style={{ fontSize: isMobileOnly ? 12 : 14, fontWeight: 400, color: '#2F4858' }}>
									{' '}
									{t(description)}{' '}
								</Text>
							</Col>
						</Row>
					) : (
						<div style={{ marginLeft: !first && 36 }}>
							<Text style={{ fontSize: 14, fontWeight: 600, color: '#748EA0' }}> {t(title)} </Text>{' '}
							<br />
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
								{' '}
								{t(description)}{' '}
							</Text>
						</div>
					)}
				</div>
			</Col>
		);
	};

	return (
		<div>
			<Modal
				className={`${isMobileOnly ? 'ModalCommonSmall ModalDoc' : 'ModalCommon'} ${
					platform === 'gibobs' && (isMobileOnly ? 'ModalNextStepMobile' : 'ModalNextStep')
				}`}
				open={activeModals}
				centered
				onCancel={() => setActiveModals(false)}
				onOk={() => {
					clickNextModal2();
				}}
				width={isMobileOnly ? 328 : platform === 'gibobs' ? 952 : 480}
				okText={
					<Text style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>
						{platform === 'gibobs' ? t('doc.start') : t('modalDocument.uploadDocs')}
					</Text>
				}
				title={
					<Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
						{platform === 'gibobs' ? t('doc.nextSteps') : t('modal1.title1')}
					</Text>
				}
				cancelButtonProps={{
					style: { border: 'none', contentVisibility: 'hidden', display: 'none' }
				}}
				okButtonProps={{
					style: { width: '100%', height: 40, marginLeft: 0 },
					className: `${closeDisabled ? 'disabled-button-sabadell' : ''} button-primari-gibobsNew `,
					disabled: platform === 'gibobs' ? activeButton : closeDisabled,
					loading: (platform === 'gibobs' || !closeDisabled) && activeButton
				}}
				destroyOnClose={true}
				closable={platform === 'gibobs' ? true : !closeDisabled}
				maskClosable={false}>
				{platform === 'gibobs' ? (
					<>
						<div
							style={{
								textAlign: 'center',
								backgroundColor: '#EFFCFC',
								padding: 16,
								borderRadius: 4
							}}>
							{!isMobileOnly && (
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<img
										src="/images/documents/documents_icon.png"
										alt="Documents icon"
										width={40}
										height={40}
									/>
									<Divider
										style={{
											width: 180,
											minWidth: 0,
											borderColor: '#02C3CD',
											marginLeft: 33,
											marginRight: 33
										}}
									/>
									<img
										src="/images/documents/mirror_icon.png"
										alt="Mirror icon"
										width={40}
										height={40}
									/>
									<Divider
										style={{
											width: 180,
											minWidth: 0,
											borderColor: '#02C3CD',
											marginLeft: 33,
											marginRight: 33
										}}
									/>
									<img
										src="/images/documents/rocket_icon.png"
										alt="Rocket icon"
										width={40}
										height={40}
									/>
								</div>
							)}
							<Row>
								{renderSteps('documents_icon.png', 'doc.complete', 'doc.needBasicDoc', true, false)}
								{renderSteps('mirror_icon.png', 'doc.best', 'doc.showOffers', false, false)}
								{renderSteps('rocket_icon.png', 'doc.choose', 'doc.formalize', false, true)}
							</Row>
							<Row justify={'center'} style={{ marginTop: isMobileOnly ? 8 : 16 }}>
								<Collapse
									onChange={() => setShowSecurity(!showSecurity)}
									className="security"
									expandIconPosition="right"
									bordered={false}
									style={{ background: 'none', padding: '0px !important' }}>
									<Panel
										header={
											<div>
												{t('doc.security')}
												{!showSecurity ? (
													<DownOutlined style={{ marginLeft: 8 }} />
												) : (
													<UpOutlined style={{ marginLeft: 8 }} />
												)}
											</div>
										}
										key="1">
										<Row style={{ textAlign: 'left', width: '100%' }}>
											<div
												style={{
													fontSize: isMobileOnly ? 10 : 12,
													fontWeight: 400,
													color: '#748EA0'
												}}
												dangerouslySetInnerHTML={{
													__html: t('doc.protectedDocs')
												}}></div>
										</Row>
										<Row gutter={16} align={'middle'} style={{ marginTop: 8, textAlign: 'left' }}>
											<Col
												span={isMobileOnly ? 24 : 6}
												style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
												<img src="/images/documents/banco_espana.svg" alt="Bank of Spain icon" />
												<div
													style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}
													dangerouslySetInnerHTML={{
														__html: t('doc.spainBank')
													}}></div>
											</Col>
											<Col
												span={isMobileOnly ? 24 : 6}
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: 8,
													marginTop: isMobileOnly && 8
												}}>
												<img
													src="/images/documents/PSD2_stars.svg"
													alt="PSD2 icon"
													width={isMobileOnly ? 24 : 32}
													height={isMobileOnly ? 24 : 32}
												/>
												<div
													style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}
													dangerouslySetInnerHTML={{
														__html: t('doc.PSD2')
													}}></div>
											</Col>
											<Col
												span={isMobileOnly ? 24 : 6}
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: 8,
													marginTop: isMobileOnly && 8
												}}>
												<img
													src="/images/documents/icon-rgpd.svg"
													alt="Data protection Icon"
													width={isMobileOnly ? 24 : 32}
													height={isMobileOnly ? 24 : 32}
												/>
												<Text style={{ fontSize: 12, fontWeight: 600, color: '#748EA0' }}>
													{t('loan.adaptedGeneralDataProtection2')}
												</Text>
											</Col>
											<Col
												span={isMobileOnly ? 24 : 6}
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: 8,
													marginTop: isMobileOnly && 8
												}}>
												<LockOutlined style={{ fontSize: 22, color: '#748EA0' }} />
												<div
													style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}
													dangerouslySetInnerHTML={{
														__html: t('doc.digitalSecurity')
													}}></div>
											</Col>
										</Row>
									</Panel>
								</Collapse>
							</Row>
						</div>
						<div style={{ marginTop: 16, textAlign: 'center', width: '100%' }}>
							<Text style={{ fontSize: isMobileOnly ? 14 : 16, fontWeight: 600, color: '#2F4858' }}>
								{t('doc.ownerDocs') + ' ' + clickTab.charAt(clickTab.length - 1)}
							</Text>
						</div>
					</>
				) : (
					<>
						<div style={{ marginTop: -24 }}>
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
								{operationType === 'mortgage' && showSavings
									? t('modalDocument.instructions')
									: t('modalDocument1.Desc1')}
							</Text>
						</div>
						{operationType !== 'mortgage' && showSavings && (
							<div
								style={{
									fontSize: 12,
									fontWeight: 400,
									color: '#748EA0',
									marginTop: 16,
									marginBottom: 24
								}}
								dangerouslySetInnerHTML={{
									__html: t('modalDocument1.Desc2')
								}}></div>
						)}
						{operationType === 'mortgage' && showSavings && (
							<Form layout="horizontal" form={form}>
								<Form.Item
									className="upload-docs-sabadell"
									name="savings"
									style={{ marginTop: 16 }}
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{t('form.Savings')}
										</Text>
									}
									colon={false}
									required>
									<InputNumber
										onChange={(value: any) => {
											value > 0 ? setCloseDisabled(false) : setCloseDisabled(true);
										}}
										style={{ borderColor: closeDisabled ? '#FF7A7B' : '#99C5FF' }}
									/>
								</Form.Item>
							</Form>
						)}
					</>
				)}
			</Modal>
		</div>
	);
}

export default AutomaticDocumentationStartupModal;
